<template>
    <div>
      <title>System Savers | Laptops</title>
      <section class="content-row content-row-color content-gamebanner">
            <div class="container">
              <header
                class="content-header content-header-large content-header-uppercase">
                <h1><mark>Laptops</mark></h1>
              </header>
            </div>
      </section>
      <section class="content-row content-row-color content-row-gray content-gamebanner">
          <div class="column-row align-center-bottom" >
            <div
              class="column-33 gamebg gamebg-acer"
              @click="open('/devices/laptops/acer')"
            >
              <div class="bluebg"></div>
              <div class="colum-game-content">
                <div class="content-game-title">Acer</div>
                <div class="content-game-subtitle">
                  Starting at just $1.00<small></small>
                </div>
              </div>
            </div>
  
            <div
              class="column-33 gamebg gamebg-alienware"
              @click="open('/devices/laptops/alienware')"
            >
              <div class="bluebg"></div>
              <div class="colum-game-content">
                <div class="content-game-title">Alienware</div>
                <div class="content-game-subtitle">
                  Starting at just $0.90<small></small>
                </div>
              </div>
            </div>

            <div
              class="column-33 gamebg gamebg-apple"
              @click="open('/devices/laptops/apple')"
            >
              <div class="bluebg"></div>
              <div class="colum-game-content">
                <div class="content-game-title">Apple</div>
                <div class="content-game-subtitle">
                  Starting at just $0.90<small></small>
                </div>
              </div>
            </div>
        </div>

        <div class="column-row align-center-bottom" >
            <div
              class="column-33 gamebg gamebg-dell"
              @click="open('/devices/laptops/dell')"
            >
              <div class="bluebg"></div>
              <div class="colum-game-content">
                <div class="content-game-title">Dell</div>
                <div class="content-game-subtitle">
                  Starting at just $1.00<small></small>
                </div>
              </div>
            </div>

            <div
              class="column-33 gamebg gamebg-gateway"
              @click="open('/devices/laptops/gateway')"
            >
              <div class="bluebg"></div>
              <div class="colum-game-content">
                <div class="content-game-title">Gateway</div>
                <div class="content-game-subtitle">
                  Starting at just $0.90<small></small>
                </div>
              </div>
            </div>

            <div
              class="column-33 gamebg gamebg-hp"
              @click="open('/devices/laptops/hp')"
            >
              <div class="bluebg"></div>
              <div class="colum-game-content">
                <div class="content-game-title">HP</div>
                <div class="content-game-subtitle">
                  Starting at just $70<small></small>
                </div>
              </div>
            </div>
        </div>

        <div class="column-row align-center-bottom" >
            <div
              class="column-33 gamebg gamebg-msi"
              @click="open('/devices/laptops/msi')"
            >
              <div class="bluebg"></div>
              <div class="colum-game-content">
                <div class="content-game-title">MSI</div>
                <div class="content-game-subtitle">
                  Starting at just $1.00<small></small>
                </div>
              </div>
            </div>
          </div>
      </section>
    </div>
  </template>
  
  <script>
  export default {
    methods: {
      open(page) {
        this.$router.push({
          path: page,
        });
      },
    },
  };
  </script>
  
  <style scoped>
  .gamebg-acer {
    background: url(https://cdn.system-savers.com/images/devices/laptops/brands/acer.jpg) center center no-repeat;
  }
  .gamebg-alienware {
    background: url(https://cdn.system-savers.com/images/devices/laptops/brands/alienware.jpeg) center center no-repeat;
  }
  .gamebg-apple {
    background: url(https://cdn.system-savers.com/images/devices/laptops/brands/apple.jpg) center center no-repeat;
  }
  .gamebg-dell {
    background: url(https://cdn.system-savers.com/images/devices/laptops/brands/dell.jpg) center center no-repeat;
  }
  .gamebg-gateway {
    background: url(https://cdn.system-savers.com/images/devices/laptops/brands/gateway.jpg) center center no-repeat;
  }
  .gamebg-hp {
    background: url(https://cdn.system-savers.com/images/devices/laptops/brands/hp.png) center center no-repeat;
  }
  .gamebg-msi {
    background: url(https://cdn.system-savers.com/images/devices/laptops/brands/msi.jpg) center center no-repeat;
  }
  
  .gamebg {
    padding: 0px;
    border-radius: 12px;
    background-size: cover;
    margin: 8px;
    max-width: 420px;
    height: 200px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }
  .gamebg:hover {
    transition: 0.25s;
    transform: scale(1.02);
    cursor: pointer;
  }
  .gamebg:hover .bluebg {
    opacity: 1;
  }
  
  .gamebg:hover .colum-game-content {
    position: absolute;
    bottom: 0px;
    margin: auto;
    color: white;
  }
  .colum-game-content {
    padding: 30px;
    padding-bottom: 20px;
    position: absolute;
    bottom: 0;
    margin: auto;
    color: white;
  }
  .content-game-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  .content-game-subtitle {
    font-size: 18px;
    filter: opacity(0.9);
  }
  
  .bluebg {
    z-index: 1000;
    width: 100%;
    height: 100%;
    z-index: 999;
    background: #10b981c9;
    content: " ";
    border-radius: 12px;
  
    transition: 0.25s;
    opacity: 0;
    top: 0;
  }
  </style>
  
  